@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pt10 {
  padding-top: 0.3125rem;
}

.pl10 {
  padding-left: 0.3125rem;
}

.pb10 {
  padding-bottom: 0.3125rem;
}

.pt20 {
  padding-top: 0.625rem;
}

.pr20 {
  padding-right: 0.625rem;
}

.pr30 {
  padding-right: 0.9375rem;
}

.pb25 {
  padding-bottom: 0.78125rem;
}

.pt30 {
  padding-top: 0.9375rem;
}

.pb30 {
  padding-bottom: 1.09375rem;
}

.pt40 {
  padding-top: 1.25rem;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

a {
  color: #fff;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

.mt5 {
  margin-top: 0.15625rem;
}

.mt10 {
  margin-top: 0.3125rem;
}

.ml10 {
  margin-left: 0.3125rem;
}

.mt15 {
  margin-top: 0.46875rem;
}

.mr15 {
  margin-right: 0.46875rem;
}

.ml15 {
  margin-left: 0.46875rem;
}

.mt25 {
  margin-top: 0.78125rem;
}

.mt40 {
  margin-top: 1.25rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.center {
  margin: 0 auto;
}

.box {
  display: flex;
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.fex1,
.flex1 {
  flex: 1 1;
}

.fex2,
.flex2 {
  flex: 2 1;
}

.items-center {
  align-items: center;
}

.small {
  font-size: 0.375rem;
}

.litter {
  font-size: 0.3125rem;
}

.big35 {
  font-size: 0.32375rem;
}

.big {
  font-size: 0.41625rem;
}

.red {
  color: #ff456d;
}

.blue {
  color: #23c1ff;
}

.green {
  color: #38ffcf;
}

.dark-gold {
  color: #CC8B3A;
}

.gold {
  color: #FFF000;
}

.silver {
  color: #FEFEFE;
}

.white {
  color: #fff;
}

.gray {
  color: #9C9C9C;
}

.rose {
  color: #EC5C42;
}

.a5 {
  color: #A5A5A5;
}

.gray1 {
  color: #ccc;
}