.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.3125rem + 1vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 0.03125rem dashed #d9d9d9;
  border-radius: 0.0625rem;
  cursor: pointer;
  transition: border-color 0.3s;
}

.ant-upload.ant-upload-drag .ant-upload-drag-icon .anticon {
  color: #40a9ff;
  font-size: 1.5rem;
}

.ant-upload.ant-upload-drag .ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 0.125rem;
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.5rem;
}

.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.4375rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  outline: 0;
}