.discla_header {
  padding-top: 0.625rem;
  background-color: #2f2d2d;
  color: #aa9d88;
  padding-bottom: 0.625rem;
}

.discla_header h2 {
  color: #ac9e75;
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 1.875rem;
}

.discla_header .summary {
  font-size: 0.5rem;
}

.discla_header .summary p {
  line-height: 0.875rem;
  margin: 0;
}

.discla_content {
  padding: 0.625rem;
  font-size: 0.4375rem;
  background-color: #2f2d2d;
  color: #aa9d88;
  border-top: 0.03125rem solid #1c1b1b;
  border-bottom: 0.03125rem solid #1c1b1b;
}

.discla_content p {
  text-indent: 0.875rem;
  margin: 0;
  margin-bottom: 0.1875rem;
  font-size: 0.4375rem;
}

.discla_footer {
  padding-top: 0.625rem;
  background-color: #2f2d2d;
  color: #fff;
  padding-bottom: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-size: 0.4375rem;
}

.discla_footer .select_control {
  padding-left: 0.625rem;
  color: #ccc;
  justify-content: flex-end;
}

.discla_footer .remark {
  color: #ccc;
}

.discla_footer .datax {
  background: transparent;
  font-size: 0.4375rem;
  color: #fff;
}

.discla_footer .datax .am-list-body {
  background: transparent;
}

.discla_footer .datax .am-list-body .am-list-item {
  background: transparent;
  height: auto;
  min-height: auto;
}

.discla_footer .datax .am-list-body .am-list-item .am-input-label {
  font-size: 0.4375rem;
  color: #fff;
}

.discla_footer .datax .am-list-body .am-list-item .am-list-line {
  padding-right: 0;
}

.discla_footer .datax .am-list-body .am-list-item .am-list-line::after {
  height: 0;
}

.discla_footer .datax .am-list-body .am-list-item .am-input-control {
  font-size: 0.4375rem;
}

.discla_footer .datax .am-list-body .am-list-item .am-input-control input {
  font-size: 0.4375rem;
  text-align: right;
  color: #fff;
}

.discla_footer .datax .am-list-body .am-list-item.am-input-item {
  padding: 0;
}

.discla_footer .datax .am-list-body::after {
  height: 0;
}

.discla_footer .datax .am-list-body::before {
  height: 0;
}

.discla_footer .checkbox-inline {
  color: #108ee9;
}

.discla_footer .ok-radio .am-radio {
  border-bottom: 0.03125rem solid #108ee9;
  margin-right: 0.15625rem;
  vertical-align: middle;
}

.corner {
  width: 0;
  /*  宽高设置为0，很重要，否则达不到效果 */
  height: 0;
  border: 0.1875rem solid #ccc;
  border-bottom-color: transparent;
  /* 设置透明背景色 */
  border-left-color: transparent;
  border-right-color: transparent;
  position: relative;
  top: 0.25rem;
  margin-left: 0.3125rem;
}