.container {
  background: url(../../images/bg.jpg) no-repeat;
  background-size: 100% auto;
  color: #FFFEFE;
  padding: 0 0.24975rem 0.222rem;
  background-color: #000;
}

.container .credit_header {
  height: 9.25rem;
  padding-top: 4.30125rem;
  text-align: center;
}

.container .credit_header .select_container {
  width: 70%;
  margin: 0 auto;
}

.container .credit_header .select_container .datevh {
  min-width: 1.3875rem;
}

.container .credit_header .select_container .corner {
  width: 0;
  /*  宽高设置为0，很重要，否则达不到效果 */
  height: 0;
  border: 0.185rem solid #FF2F00;
  border-bottom-color: transparent;
  /* 设置透明背景色 */
  border-left-color: transparent;
  border-right-color: transparent;
  position: relative;
  top: 0.13875rem;
  margin-left: 0.185rem;
}

.container .credit_header .credit_select {
  padding-left: 0.37rem;
  height: 0.69375rem;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0.2775rem;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0.37rem;
}

.container .credit_content {
  display: block;
}

.container .credit_content .mtab .am-tabs-default-bar-content {
  align-items: flex-end;
  justify-content: center;
}

.container .credit_content .mtab .am-tabs-default-bar-tab {
  height: 0.925rem;
  background: url(../../images/tab_unactive.png) no-repeat;
  background-size: 100% auto;
  font-size: 0.4255rem;
  width: 2.96rem !important;
  margin-left: 0.37rem;
}

.container .credit_content .mtab .am-tabs-default-bar-tab-active {
  height: 1.295rem;
  background: url(../../images/tab_active.png) no-repeat;
  background-size: 100% auto;
  color: #fed9ba;
  font-size: 0.4255rem;
  margin-left: 0.37rem;
}

.container .credit_content .mtab .am-tabs-default-bar-underline {
  display: none;
}

.container .credit_content .credit_tab_content {
  background: url(../../images/tabcg.jpg) no-repeat;
  background-size: 100% auto;
  padding: 0.2775rem;
  background-color: #000;
}

.container .credit_content .credit_tab_content .thirdTabs {
  justify-content: flex-end;
  width: 100%;
}

.container .credit_content .credit_tab_content .thirdTabs .third {
  margin-left: 0.2775rem;
}

.container .credit_content .credit_tab_content .thirdTabs .selected {
  color: #FEF671;
}

.container .credit_content .credit_tab_content .ownerbas {
  background: url(../../images/avator_bg.png) no-repeat;
  background-size: 100% 100%;
  padding-bottom: 0.4625rem;
}

.container .credit_content .credit_tab_content .ownerbas .owner-left {
  width: 40%;
}

.container .credit_content .credit_tab_content .ownerbas .owner-right {
  width: 60%;
  padding-top: 0.925rem;
  text-align: center;
}

.container .credit_content .credit_tab_content .ownerbas .bt {
  padding-top: 0.23125rem;
}

.container .credit_content .credit_tab_content .ownerbas .bt .crown {
  width: 1.11rem;
  height: 0.69375rem;
  position: relative;
  top: 0.04625rem;
}

.container .credit_content .credit_tab_content .ownerbas .bt .avater {
  border-radius: 1.48rem;
  width: 1.48rem;
  height: 1.48rem;
  overflow: hidden;
}

.container .credit_content .credit_tab_content .ownerbas .bt .avater .head {
  width: 1.48rem;
  height: 1.48rem;
}

.container .credit_content .credit_tab_content .ownerbas .bt-title {
  font-size: 0.3145rem;
  padding-top: 0.185rem;
}

.container .credit_content .credit_tab_content .ownerbas .bt-title .litter {
  font-size: 0.259rem;
}

.container .credit_content .credit_tab_content .ownerbas .bt-title .number {
  font-size: 0.4255rem;
}

.container .credit_content .credit_tab_content .thirdbas {
  background: url(../../images/3rd.png) no-repeat;
  background-size: 100% 100%;
  padding-bottom: 0.23125rem;
  display: block;
}

.container .credit_content .credit_tab_content .thirdbas .mst {
  padding-top: 0.37rem;
}

.container .credit_content .credit_tab_content .thirdbas .mst .crown {
  width: 0.69375rem;
  height: 0.555rem;
  position: relative;
  top: 0.04625rem;
}

.container .credit_content .credit_tab_content .thirdbas .mst .avater {
  border-radius: 1.48rem;
  width: 1.48rem;
  height: 1.48rem;
  overflow: hidden;
}

.container .credit_content .credit_tab_content .thirdbas .mst .avater .head {
  width: 1.48rem;
  height: 1.48rem;
}

.container .credit_content .credit_tab_content .thirdbas .bt {
  padding-top: 0.74rem;
}

.container .credit_content .credit_tab_content .thirdbas .bt .crown {
  width: 0.69375rem;
  height: 0.555rem;
  position: relative;
  top: 0.04625rem;
}

.container .credit_content .credit_tab_content .thirdbas .bt .avater {
  border-radius: 1.11rem;
  width: 1.11rem;
  height: 1.11rem;
  overflow: hidden;
}

.container .credit_content .credit_tab_content .thirdbas .bt .avater .head {
  width: 1.11rem;
  height: 1.11rem;
}

.container .credit_content .credit_tab_content .thirdbas .bt-title {
  font-size: 0.3145rem;
}

.container .credit_content .credit_tab_content .thirdbas .bt-title h3 {
  width: 100%;
  line-height: 0.6475rem;
  height: 0.6475rem;
  overflow: hidden;
}

.container .credit_content .credit_tab_content .thirdbas .bt-title .litter {
  font-size: 0.259rem;
}

.container .credit_content .credit_tab_content .thirdbas .bt-title .number {
  font-size: 0.4255rem;
}

.container .credit_content .credit_tab_content .rank_list {
  padding: 0.2775rem 0.41625rem;
  background-color: #1B1B1B;
  margin-top: 0.23125rem;
}

.container .credit_content .credit_tab_content .rank_list .rank_li {
  border-bottom: 0.0625rem solid #6F6F6F;
  padding: 0.37rem 0;
  width: 100%;
}

.container .credit_content .credit_tab_content .rank_list .rank_li .nub {
  width: 6%;
}

.container .credit_content .credit_tab_content .rank_list .rank_li .rank_ico {
  width: 17%;
}

.container .credit_content .credit_tab_content .rank_list .rank_li .rank_ico .avater {
  border-radius: 1.11rem;
  width: 1.11rem;
  height: 1.11rem;
  overflow: hidden;
}

.container .credit_content .credit_tab_content .rank_list .rank_li .rank_ico .avater img {
  width: 1.11rem;
  height: 1.11rem;
}

.container .credit_content .credit_tab_content .rank_list .rank_li .rank_name {
  width: 27%;
}

.container .credit_content .credit_tab_content .rank_list .rank_li .rceil {
  text-align: center;
  width: 25%;
}

.container .credit_content .credit_tab_content .rank_list .rank_li.last {
  border-bottom: 0;
}

.container .credit_content .credit_tab_content .remark {
  padding: 0.37rem;
  background-color: #1B1B1B;
  border-radius: 0.2775rem;
  font-size: 0.2405rem;
}

.container .credit_content .credit_tab_content .remark .title {
  width: 100%;
  text-align: center;
  padding-bottom: 0.2775rem;
}

.am-picker-popup-wrap .am-picker-popup-item {
  height: auto;
  color: #FF2F00;
}